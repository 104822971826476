import { atom } from 'recoil';

export const isOpenMenuState = atom({
  key: 'isOpenMenuState',
  default: false,
});


export const isStartState = atom({
  key: 'isStartState',
  default: false,
});


export const isMouseActive = atom({
  key: 'isMouseActive',
  default: false,
});

export const targetSizeState = atom({
  key: 'targetSizeState',
  default: 180,
});

export const targetColorState = atom({
  key: 'targetColorState',
  default: 'rgba(255,255,255,1)',
});

export const isCurrentlState = atom({
  key: 'isCurrentlState',
  default: null,
});




