import React, {useEffect , useState} from "react";
import { ReactComponent as Logo} from './svg/logo.svg';
import { useNavigate } from 'react-router-dom';
function NotFound() {
  const navigate = useNavigate();

  return (
    <section className="NotFound">
      <div className="NotFoundWrap">
        <Logo/>
        <h1>PAGE NOT FOUND</h1>
        <h6>There's nothing here</h6>
        <button className="ExploreBtn"  onClick={() => navigate('/')}><p>Home</p><span></span></button>
      </div>
    </section>
  );
}

export default NotFound;
