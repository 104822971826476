import React from 'react';
import { useEffect ,useState, useRef} from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil';
import './styles.css'
import App from "./App"
import ReactDOM from 'react-dom/client';
import NotFound from './NotFound';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

function Root() {
  return (
    <BrowserRouter >
      <RecoilRoot>
      <Routes>
        <Route path="/" element={<App/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      </RecoilRoot>
    </BrowserRouter>
  )
}

createRoot(document.getElementById('root')).render(<Root />)
